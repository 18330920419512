import config from '@/config/env-constants';
import _ from 'lodash';


function allowManagerAccount(user, users) {
	let isManager = user.type === config.managerRole;
	if (!isManager) {
		return true;
	}

	let activeManagers = _.filter(users, o => {
		return o.isActive === "true" &&
			user.companyId === o.companyId &&
			o.type === config.managerRole;
	});

	const isManagerLimitExceeded = activeManagers.length >= 3;
	const isPartOfExistingManagers = isManager && activeManagers.length > 0 && _.find(activeManagers, { 'id': user.id });

	return !isManagerLimitExceeded || isPartOfExistingManagers;
}

function exceedMaximum(company, users, currUser) {
	let maxNo = company.noOfUsers ? company.noOfUsers : 0;
	if (!_.isEmpty(users)) {
		let activeUsers = _.filter(users, o => {
			return o.isActive === 'true' && company.id === o.companyId;
		});

		let activeUserIds = _.map(activeUsers, 'id');
		if (currUser && activeUserIds.includes(currUser.id)) {
			// skip if the currUser is already part of current active users
			return false;
		} else {
			return _.size(activeUsers) >= maxNo;
		}
	}
	return false;
}

function isSuperAdmin(userEmailAddress) {
	return userEmailAddress === config.adminAccount.TAWI_SUPPORT;
}

function getDefaultCompanyAccess(user, allCompaniesObj) {
	let companyAccess = [];

	// default access: current company ONLY
	let currCompanyObj = allCompaniesObj[user.companyId];
	companyAccess.push({
		id: currCompanyObj.id,
		name: currCompanyObj.name,
		description: currCompanyObj.description
	});

	return companyAccess;
}

function cleanupFields(user) {
	let cleanedDispatch = { ...user };

	delete cleanedDispatch['_showDetails'];

	return cleanedDispatch;
}

function getDefaultScanners(usersObj, companyId) {
	const scanners = [];
	_.forEach(usersObj, user => {
		if (user.companyId === companyId) {
			scanners.push({
				id: user.id,
				name: user.firstName + ' ' + user.lastName,
				scannedAssets: [],
				type: user.type,
			});
		}
	});
	return scanners;
}

function hasCompanyAccess(companiesObj, companyId) {
	let companyObj = _.find(companiesObj, (o) => { return o.id === companyId });
	return companyObj && !_.isEmpty(companyObj);
}

export const UserUtil = {
	allowManagerAccount,
	exceedMaximum,
	isSuperAdmin,
	getDefaultCompanyAccess,
	getDefaultScanners,
	cleanupFields,
	hasCompanyAccess
}
